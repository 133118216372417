import { EventServiceClient } from "../../proto/pb/EventsServiceClientPb";
import {
  Event,
  EventSort,
  ListEventRequest,
  EventPagination,
  LastEventRequest,
} from "../../proto/pb/events_pb";
import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";

const defaultLimit: number = 1000;

export class EventsApi {
  Actions: Array<Event>;
  client: EventServiceClient;
  metadata: any;

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? "";
    this.client = new EventServiceClient(host, null, {
      unaryInterceptors: [new RefreshTokenInterceptor()],
    });
    this.metadata = {
      Authorization: localStorage.getItem("access-key"),
    };
  }

  createSort(data: any) {
    const sort = new EventSort();
    const name: string =
      data.name !== undefined ? data.name.toString() : "event_date";
    const exp: string = data.exp !== undefined ? data.exp.toString() : "desc";

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  createPagination(data: any) {
    const pagination = new EventPagination();
    const page: number = data.page !== undefined ? Number(data.page) : 1;
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);
    pagination.setCnt(cnt);

    return pagination;
  }

  getEventsList(filter: any, pagination: any, sort: any = {}) {
    const request = new ListEventRequest();
    console.log("EVENT REQ ", filter, pagination);
    // Устанавливаем параметры запроса
    request
      .setActionNamesList(filter.action_names)
      .setDateBegin(filter.date_begin)
      .setDateEnd(filter.date_end)
      .setProjectIdsList(filter.project_ids)
      .setSsoId(filter.sso_id)
      .setPagination(this.createPagination(pagination))
      .setSort(this.createSort(sort));

    return this.client.listEvent(request, {
      Authorization: localStorage.getItem("access-key"),
    } as any);
  }

  getLastActivity(id: any) {
    const request = new LastEventRequest();

    const date = new Date().toJSON().slice(0, 10);

    request.setSsoId(id).setDateBegin("2012-01-01").setDateEnd(date);

    return this.client.lastEvent(request, {
      Authorization: localStorage.getItem("access-key"),
    } as any);
  }

  getEventsCount(filter: any, pagination: any, sort: any = {}) {
    const request = new ListEventRequest();
    // Устанавливаем параметры запроса
    request
      .setActionNamesList(filter.action_names)
      .setDateBegin(filter.date_begin)
      .setDateEnd(filter.date_end)
      .setProjectIdsList(filter.project_ids)
      .setSsoId(filter.sso_id)
      .setPagination(this.createPagination(pagination))
      .setSort(this.createSort(sort));

    return this.client.countEvent(request, {
      Authorization: localStorage.getItem("access-key"),
    } as any);
  }
}
