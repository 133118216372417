import { TagsServiceClient } from "../../proto/pb/TagsServiceClientPb";
import {
  Tags,
  TagsFilter,
  TagsFilterItem,
  TagsSort,
  TagsPagination,
  CreateTagsRequest,
  UpdateTagsRequest,
  DeleteTagsRequest,
  ListTagsRequest,
  ListTagsResponse,
  TagsRatio,
} from "../../proto/pb/tags_pb";
import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";

export class TagsApi {
  client: TagsServiceClient;
  metadata: any;

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? "";
    this.client = new TagsServiceClient(host, null, {
      unaryInterceptors: [new RefreshTokenInterceptor()],
    });
    this.metadata = {
      Authorization: localStorage.getItem("access-key"),
    };
  }

  createFilter(data: any) {
    const filter = new TagsFilter();
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && "" !== data[i].field_name) {
          const fItem = new TagsFilterItem();
          fItem.setFieldName(data[i].field_name.toString());
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand : "="
          );
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ""
          );
          fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
          filter.addItems(fItem);
        }
      }
    }
    return filter;
  }

  createSort(data: any) {
    const sort = new TagsSort();
    const name: string = data.name !== undefined ? data.name.toString() : "";
    const exp: string = data.exp !== undefined ? data.exp.toString() : "asc";

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  createPagination(data: any) {
    const pagination = new TagsPagination();
    const page: number = data.page !== undefined ? Number(data.page) : 1;
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : 100000;
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages).setCnt(cnt);

    return pagination;
  }

  getTagsList(
    filter: TagsFilter.AsObject[] | [],
    sort: TagsSort.AsObject | {},
    pagination: TagsPagination.AsObject | {}
  ): Promise<ListTagsResponse> {
    const req = new ListTagsRequest();
    req
      .setFilter(this.createFilter(filter))
      .setSort(this.createSort(sort))
      .setPagination(this.createPagination(pagination));

    return this.client.listTags(req, this.metadata);
  }

  tagsItem(data: Tags.AsObject) {
    const tagsItem = new Tags();

    tagsItem
      .setTagId(data.tagId)
      .setTagName(data.tagName)
      .setTagDescr(data.tagDescr)
      .setTagParentId(data.tagParentId)
      .setTagColor(data.tagColor)
      .setTagInUse(data.tagInUse);

    const ratiosArr: TagsRatio[] = [];

    data.metricsList.forEach((el) => {
      const object = new TagsRatio();

      object
        .setTagId(el.tagId)
        .setMetricId(el.metricId)
        .setMetricIdx(el.metricIdx)
        .setMetricName(el.metricName)
        .setRatioId(el.ratioId)
        .setMetricRatio(el.metricRatio);

      ratiosArr.push(object);
    });

    tagsItem.setMetricsList(ratiosArr);

    return tagsItem;
  }

  createTags(data: CreateTagsRequest.AsObject) {
    const req = new CreateTagsRequest();
    req
      .setTagName(data.tagName)
      .setTagDescr(data.tagDescr)
      .setTagParentId(data.tagParentId)
      .setTagColor(data.tagColor);

    const ratiosArr: TagsRatio[] = [];

    data.tagRatiosList.forEach((el) => {
      const object = new TagsRatio();

      object
        .setTagId(el.tagId)
        .setMetricId(el.metricId)
        .setMetricIdx(el.metricIdx)
        .setMetricName(el.metricName)
        .setRatioId(el.ratioId)
        .setMetricRatio(el.metricRatio);

      ratiosArr.push(object);
    });

    req.setTagRatiosList(ratiosArr);

    return this.client.createTags(req, this.metadata);
  }

  updateTags(data: Tags.AsObject) {
    const req = new UpdateTagsRequest();

    req.setItem(this.tagsItem(data));

    console.log("update tags req", req);

    return this.client.updateTags(req, this.metadata);
  }

  deleteTags(data: Tags.AsObject) {
    const req = new DeleteTagsRequest();

    req.setItem(this.tagsItem(data));
    console.log('%c[INFO]:Delete tag request', 'color:orange', req.toObject())

    return this.client.deleteTags(req, this.metadata).then((res) => {
      console.log('%c[INFO]:Delete tag response', 'color:orange', res.toObject())
      return res
    })
  }
}
