import script from "./SidebarComponent.vue?vue&type=script&setup=true&lang=ts"
export * from "./SidebarComponent.vue?vue&type=script&setup=true&lang=ts"

import "./SidebarComponent.vue?vue&type=style&index=0&id=62e6280b&lang=scss"

const __exports__ = script;

export default __exports__
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import qInstall from "../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInnerLoading,QIcon,QTooltip,QList,QItem,QItemSection,QTabs,QBtn,QTab});
