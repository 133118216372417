import script from "./ProjectPage.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProjectPage.vue?vue&type=script&setup=true&lang=ts"

import "./ProjectPage.vue?vue&type=style&index=0&id=49939ca8&lang=scss"

const __exports__ = script;

export default __exports__
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';import ClosePopup from 'quasar/src/directives/close-popup/ClosePopup.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QPopupProxy,QDate,QBtn,QTable,QSelect,QInnerLoading,QTooltip,QTr,QTh,QTd});qInstall(script, 'directives', {ClosePopup});
